import Image from "next/image";
import Link from "next/link";
import routes from "@/constants/routes";
import { mobileConversionStore } from "@/components/shared/gtm";
import getConfig from "next/config";

export const Footer = () => {
  const { publicRuntimeConfig } = getConfig();

  const footerLinks = [
    {
      title: "Guides",
      href: routes.guides,
    },
    {
      title: "Privacy",
      href: routes.privacy,
    },
    {
      title: "Terms",
      href: routes.terms,
    },
  ];

  const handleAppStoreDownloadClick = () => {
    mobileConversionStore({
      label: "apple-app-store",
    });
    window.location.href = publicRuntimeConfig.NEXT_PUBLIC_APP_STORE_URL;
  };

  const handlePlayStoreDownloadClick = () => {
    mobileConversionStore({
      label: "google-play-store",
    });
    window.location.href = publicRuntimeConfig.NEXT_PUBLIC_PLAY_STORE_URL;
  };

  const SocialLinks = () => (
    <div className="flex justify-center items-center gap-3">
      <a
        href="https://www.instagram.com/klink__app/"
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src="/social/instagram.svg"
          width="42"
          height="42"
          alt="Instagram"
        />
      </a>

      <a
        href="https://www.tiktok.com/@klink_app"
        target="_blank"
        rel="noreferrer"
      >
        <Image src="/social/tiktok.svg" width="42" height="42" alt="Tiktok" />
      </a>

      <a href="https://twitter.com/klink_app" target="_blank" rel="noreferrer">
        <Image src="/social/twitter.svg" width="42" height="42" alt="Twitter" />
      </a>
    </div>
  );

  const DownloadAppButtons = () => (
    <div className="flex flex-col md:flex-row justify-end items-center gap-6">
      <h3 className="font-bold"> Get the app</h3>
      <div className="flex gap-4">
        <a
          href={publicRuntimeConfig.NEXT_PUBLIC_APP_STORE_URL}
          onClick={() => handleAppStoreDownloadClick()}
        >
          <Image
            src="/koach/app-store.svg"
            width="44"
            height="44"
            alt="App store"
            className="cursor-pointer"
          />
        </a>

        <a
          href={publicRuntimeConfig.NEXT_PUBLIC_PLAY_STORE_URL}
          onClick={() => handlePlayStoreDownloadClick()}
        >
          <Image
            src="/koach/play-store.svg"
            width="44"
            height="44"
            alt="Google play"
            className="cursor-pointer"
          />
        </a>
      </div>
    </div>
  );

  const KlinkInfo = () => (
    <div className="flex flex-col-reverse md:flex-col md:items-start gap-8 md:gap-3">
      <Image
        src="/koach/keyzy-klink-main-logo.svg"
        width="112"
        height="38"
        alt="Klink footer logo"
      />

      <div className="text-base text-grey-500 text-center md:text-left">
        Klink App Limited is acting as an agent of TrueLayer, who is providing
        the regulated Account Information Service, and who is authorised by the
        Financial Conduct Authority under the Payment Services Regulations 2017
        and the Electronic Money Regulations 2011 (Firm Reference Number:
        901096).
      </div>
    </div>
  );

  const FooterLinks = () => (
    <div className="flex gap-10">
      {footerLinks.map((link, index) => (
        <Link href={link.href} key={index}>
          <a className="hover:underline">{link.title}</a>
        </Link>
      ))}
    </div>
  );

  const CopyrightText = () => (
    <div className="text-base text-grey-500">
      &copy; {`${new Date().getFullYear()} Klink. All rights reserved.`}
    </div>
  );

  const DevelopedByText = () => (
    <div className="text-grey-500">Made with ♡ by Keyzy</div>
  );

  const Logo = () => (
    <>
      {/* Desktop View */}
      <div className="hidden md:flex flex-col md:flex-row gap-5 justify-between items-center border-b border-grey-200 py-20">
        <div className="w-2/4">
          <KlinkInfo />
        </div>

        <div className="w-1/4">
          <SocialLinks />
        </div>

        <div className="w-1/4">
          <DownloadAppButtons />
        </div>
      </div>

      {/* Mobile view */}
      <div
        className={`md:hidden flex flex-col md:flex-row gap-8 justify-between items-center border-b border-grey-200 py-16`}
      >
        <SocialLinks />
        <FooterLinks />
        <DownloadAppButtons />
      </div>
    </>
  );

  const FooterNavs = () => (
    <>
      <div className="hidden md:flex flex-col md:flex-row gap-6 justify-between items-center py-10 md:py-12 lg:py-16">
        <div className="w-full flex flex-col md:flex-row items-center gap-6 justify-between">
          <DevelopedByText />
          <FooterLinks />
          <CopyrightText />
        </div>
      </div>

      <div className="md:hidden flex flex-col md:flex-row gap-6 justify-between items-center py-10 md:py-12 lg:py-16">
        <div className="w-full flex flex-col md:flex-row items-center gap-6 justify-between">
          <CopyrightText />
          <KlinkInfo />
          <DevelopedByText />
        </div>
      </div>
    </>
  );

  return (
    <div className="w-full px-5 lg:px-11 xl:px-32">
      <div className="flex flex-col w-full">
        <Logo />
        <FooterNavs />
      </div>
    </div>
  );
};

export default Footer;
